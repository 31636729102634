@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@/styles/base-imports";
@import "~bulma/bulma.sass";
@import "~react-toastify/dist/ReactToastify.css";
@import "@/styles/app";
@import "~bulma/sass/components/navbar.sass";
@import "~bulma/sass/form/shared.sass";
@import "~bulma/sass/base/generic.sass";
@import "material-icons/iconfont/material-icons.scss";

@layer components {
  pre {
    @apply overflow-x-auto whitespace-pre-wrap break-words bg-gray-100 p-4 rounded
  }
}

.fixed-navbar {
  padding-top: #{$fixed-nav-size};

  @media print {
    padding-top: 0;
  }
}

.styled-link {
  text-decoration: underline;
  color: $primary;
  font-weight: bold;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

body {
  font-size: 1rem;
  line-height: 1.4;
}

// Customize toastify
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #{$info};
  --toastify-color-success: #{$success};
  --toastify-color-warning: #{$danger};
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #{$text};
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55);
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

  // used to control the opacity of the progress trail
  --toastify-color-progress-bgo: .2;
}
@import "@/styles/base-imports";

.container {
    position: fixed;
    z-index: 9999;
    box-shadow: 0px 6px 10px #333;
    bottom: 0;
    width: 100%;
    padding: 2rem 2rem;
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    transform: translateY(100%);
    transition: transform .5s ease-in-out;

    @include tablet {
        flex-direction: row;
    }

    &.hidden {
        display: none;
    }

    &.loaded {
        transform: translateY(0%);

    }

    a {
        font-weight: 700;
        white-space: nowrap;
        color: white;
        text-decoration: underline;
    }

    @media print {
        display: none;
    }
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;

    button {
        cursor: pointer;
    }

    @include tablet {
        flex-direction: row-reverse;
        align-items: center;
        margin-left: auto;
    }

    .cta {
        border-radius: 4px;
        text-transform: uppercase;
        padding-inline: 1rem;
        padding-block: 1rem;
        border: none;
    }

    .secondary {
        background-color: lightgrey;
        color: darkgrey;
        padding: .5rem;
        background: none;
        border: none;

        &:active,
        &:hover,
        &:focus,
        &:target {
            background: lightgrey;
            outline: 0;
        }
    }
}